* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

div, ul, li, span, h1, h2, h3, h4, h5, h6, p, a, label, input {
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
}

a {
  text-decoration: none;
}

.modal-caller {
  cursor: pointer;
}

.header {
  z-index: 999;
  background: #eef1f0;
  flex-flow: row;
  align-items: center;
  gap: 0 24px;
  padding: 16px;
  transition: transform .125s linear;
  display: flex;
  position: sticky;
  top: 0;
  transform: translateY(-100%);
  box-shadow: 0 4px 8px #696e791a;
}

.header_hidden {
  pointer-events: none;
  z-index: -1;
  display: none;
}

.header_placed {
  transform: translateY(0);
}

.header__phone-link {
  color: #0d1321;
  flex: 1;
  font-size: 16px;
  font-weight: 500;
}

.header__call-button {
  color: #f9f9fa;
  text-transform: uppercase;
  background: #3c7168;
  border: none;
  border-radius: 8px;
  flex: 1;
  padding: 8px;
  font-weight: 500;
}

.regions-modal {
  z-index: 9999;
  background: #0000004d;
  flex-flow: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.regions-modal__list-wrapper {
  background: #eef1f0;
  border-radius: 32px 32px 0 0;
  height: calc(100% - 64px);
  padding: 32px 0 0;
  overflow: auto;
}

.regions-modal__select-button {
  color: #f9f9fa;
  text-transform: uppercase;
  background: #3c7168;
  border: none;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}

.regions-modal__selected-value {
  color: #696e79;
  font-weight: 500;
}

.regions-modal__regions-list {
  flex-flow: column;
  gap: 16px 0;
  padding: 0 16px 16px;
  list-style-type: none;
  display: flex;
}

.regions-modal__selected-wrapper {
  background: #f9f9fa;
  border-radius: 24px;
  flex-flow: column;
  gap: 16px 0;
  padding: 24px 16px;
  display: flex;
  position: sticky;
  bottom: 16px;
  box-shadow: 0 2px 8px #696e791a;
}

.regions-modal .regions-dropdown__region-list-item {
  color: #696e79;
  text-align: center;
  background: #f9f9fa;
  border: 1px solid #0000;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all .125s linear;
  display: flex;
  box-shadow: 0 2px 8px #696e791a;
}

.regions-modal .regions-dropdown__region-list-item_selected {
  background: #3c71684d;
  border-color: #3c716899;
  font-weight: 500;
}

section {
  background: #f9f9fa;
  height: 100%;
  min-height: 100vh;
  padding: 64px 32px;
  position: relative;
}

.section-title {
  color: #0d1321;
}

.section-subtitle {
  color: #696e79;
}

.common-form {
  background: #3c71684d;
  border-radius: 32px;
  flex-flow: column;
  gap: 32px 0;
  padding: 24px;
  display: flex;
}

.common-form__fields-wrapper {
  flex-flow: column;
  gap: 16px 0;
  display: flex;
}

.common-form label {
  color: #696e79;
  flex-flow: column;
  gap: 4px 0;
  font-size: 12px;
  line-height: 20px;
  display: flex;
}

.common-form__input {
  text-align: center;
  color: #696e79;
  background: #eef1f0;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 64px;
  font-size: 24px;
  font-weight: 500;
  display: flex;
}

.common-form__input::placeholder {
  color: #d3dcde;
}

.common-form__input_location {
  cursor: pointer;
}

.common-form__submit {
  color: #f9f9fa;
  text-transform: uppercase;
  cursor: pointer;
  background: #3c7168;
  border: none;
  border-radius: 8px;
  height: 64px;
  font-size: 24px;
  font-weight: 600;
  transition: background-color .125s linear;
}

.common-form__submit[disabled] {
  cursor: not-allowed;
  background: #696e79;
}

.common-form__attention-text {
  text-align: center;
  color: #f9f9fa;
  opacity: .6;
}

.common-form__info-wrapper {
  flex-flow: column;
  gap: 8px 0;
  display: flex;
}

.common-form__info-wrapper-text {
  text-align: center;
  color: #696e79;
  font-size: 16px;
  line-height: 20px;
}

.common-form__contact {
  text-align: center;
  color: #696e79;
  font-size: 20px;
  line-height: 24px;
}

.first-section__content-wrapper, .first-section__info-wrapper {
  flex-flow: column;
  gap: 32px 0;
  display: flex;
}

.first-section__title-wrapper {
  background: #eef1f0;
  border-radius: 32px;
  padding: 32px;
}

.first-section__features-wrapper {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
}

.first-section__feature {
  background: #eef1f0;
  border-radius: 16px;
  flex-flow: column;
  gap: 8px 0;
  padding: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.first-section__feature-title {
  font-size: 20px;
}

.first-section__feature_first {
  grid-area: 1 / 1 / 2 / 3;
  padding: 24px 80px 24px 24px;
}

.first-section__feature_second {
  grid-area: 2 / 1 / 3 / 2;
  padding: 24px 24px 72px;
}

.first-section__feature_second .first-section__feature-title {
  font-size: 32px;
}

.first-section__feature_third {
  grid-area: 2 / 2 / 3 / 3;
}

.first-section__feature_third .first-section__feature-title {
  font-size: 32px;
}

.first-section__map-image {
  opacity: .6;
  width: 200px;
  position: absolute;
  top: -64px;
  right: -64px;
}

.first-section__mask-cars-wrapper {
  flex-flow: row;
  display: flex;
  position: relative;
}

.first-section__mask-cars-image {
  width: 120px;
  position: absolute;
  bottom: -80px;
  left: -40px;
}

.first-section__third-feature-image-wrapper {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  position: absolute;
  bottom: -20px;
  right: -20px;
  overflow: hidden;
}

.first-section__third-feature-image {
  width: auto;
  height: 100%;
}

.first-section__mask-number {
  color: #696e79;
  background: #fff9;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  position: absolute;
  bottom: -80px;
  left: 64px;
}

.first-section__photos-wrapper {
  background: #eef1f0;
  border-radius: 24px;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 8px;
  display: grid;
}

.first-section__photo-wrapper {
  border-radius: 24px;
  display: flex;
  overflow: hidden;
}

.first-section__photo-wrapper_first {
  grid-area: 1 / 1 / 2 / 3;
}

.first-section__photo {
  width: 100%;
  height: auto;
  display: block;
}

.service-section {
  padding: 0;
}

.service-section__form-wrapper {
  display: none;
}

.service-section__calculator-wrapper {
  background: #eef1f0;
  flex-flow: column;
  gap: 48px 0;
  padding: 64px 0;
  display: flex;
}

.service-section__title-wrapper {
  flex-flow: column;
  gap: 8px 0;
  padding: 0 32px;
  display: flex;
}

.service-section__title {
  font-size: 36px;
  line-height: 42px;
}

.service-section__subtitle {
  font-size: 16px;
  line-height: 24px;
}

.service-section__content-wrapper {
  flex-flow: column;
  gap: 48px 0;
  display: flex;
}

.service-section__services-grid {
  grid-gap: 24px 0;
  grid-auto-flow: row;
  padding: 0 32px;
  display: grid;
}

.service-section__service-item {
  color: #696e79;
  text-align: center;
  background: #f9f9fa;
  border: 1px solid #0000;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: all .125s linear;
  display: flex;
  box-shadow: 0 2px 8px #696e791a;
}

.service-section__service-item_selected {
  background: #3c71684d;
  border-color: #3c716899;
  font-weight: 500;
}

.service-section__selected-service-content-wrapper {
  flex-flow: column;
  order: 1;
  gap: 16px 0;
  display: flex;
}

.service-section__toggle-mobile-service-button {
  color: #696e79;
  background: #ece9e9;
  border: none;
  border-radius: 8px;
  height: 32px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.service-section__selected-service-description {
  background: #f9f9fa;
  border-radius: 24px 24px 0 0;
  flex-flow: column;
  gap: 8px 0;
  padding: 32px 32px 16px;
  display: flex;
  position: sticky;
  bottom: 0;
  box-shadow: 0 -4px 8px #696e791a;
}

.service-section__perks-list-wrapper {
  flex-flow: row;
  align-items: center;
  gap: 0 16px;
  display: none;
}

.service-section__perks-list-wrapper_expanded {
  max-height: 100%;
  display: flex;
}

.service-section__perks-list-image-wrapper {
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  overflow: hidden;
}

.service-section__perks-list-image {
  width: 100%;
  height: auto;
  display: block;
}

.service-section__selected-service-price {
  color: #0d1321;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.service-section__perks-list {
  padding: 0 0 0 24px;
}

.service-section__perks-list-item {
  color: #696e79;
  font-size: 16px;
  line-height: 24px;
}

.service-section__selected-service-value {
  color: #696e79;
  height: 32px;
  font-weight: 500;
}

.service-section__call-form-button {
  color: #f9f9fa;
  text-transform: uppercase;
  background: #3c7168;
  border: none;
  border-radius: 8px;
  order: 2;
  height: 48px;
  font-size: 16px;
}

.reviews-section__content-wrapper {
  background: #eef1f0;
  border-radius: 32px;
  flex-flow: column;
  gap: 32px 0;
  padding: 24px;
  display: flex;
}

.reviews-section__title {
  font-size: 32px;
  line-height: 40px;
}

.reviews-section__reviews-list {
  grid-auto-flow: row;
  gap: 16px;
  display: grid;
}

.reviews-section__load-more-button {
  color: #696e79;
  background: #ece9e9;
  border: none;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
}

.review {
  background: #f9f9fa;
  border-radius: 32px;
  flex-flow: column;
  gap: 12px 0;
  display: flex;
}

.review__image-wrapper {
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  width: calc(100% - 48px);
  max-height: 240px;
  display: flex;
  overflow: hidden;
  transform: translateX(24px)translateY(24px);
}

.review__image {
  width: 100%;
  height: auto;
  display: block;
}

.review__text {
  color: #696e79;
  padding: 24px 24px 0;
  font-size: 16px;
  line-height: 24px;
}

.review__author-wrapper {
  flex-flow: column;
  align-items: flex-end;
  padding: 0 16px 16px;
  display: flex;
}

.review__author {
  color: #0d1321;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.title-badge {
  color: #696e79;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  background: #ece9e9;
  border: 1px solid #d3dcde;
  border-radius: 32px;
  justify-self: center;
  width: min-content;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 500;
}

.features-section {
  flex-flow: column;
  gap: 80px 0;
  display: flex;
}

.features-section__title-wrapper {
  flex-flow: column;
  align-items: center;
  gap: 24px 0;
  display: flex;
}

.features-section__title {
  text-align: center;
  font-size: 32px;
  line-height: 40px;
}

.features-section__subtitle {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.features-section__features-list {
  flex-flow: column;
  gap: 32px 0;
  display: flex;
}

.feature {
  background: #eef1f0;
  border-radius: 24px;
  flex-flow: column;
  display: flex;
  overflow: hidden;
}

.feature__text-wrapper {
  flex-flow: column;
  gap: 12px 0;
  padding: 24px 24px 0;
  display: flex;
}

.feature__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.feature__description {
  color: #696e79;
  font-size: 16px;
  line-height: 24px;
}

.feature__image-wrapper {
  justify-content: flex-end;
  padding: 0 0 16px;
  display: flex;
}

.feature__image-wrapper_padding {
  padding: 16px;
}

.feature__image {
  width: 50%;
  height: auto;
}

.feature__image_third {
  border-radius: 24px;
  width: 100%;
}

.process-section__content-wrapper {
  background: #eef1f0;
  border-radius: 32px;
  flex-flow: column;
  gap: 64px 0;
  padding: 32px 24px;
  display: flex;
}

.process-section__title {
  font-size: 48px;
  line-height: 56px;
}

.process-section .processes__wrapper {
  flex-flow: column;
  gap: 24px 0;
  display: flex;
}

.process {
  background: #f9f9fa;
  border-radius: 24px;
  flex-flow: column;
  flex: 1;
  gap: 32px 0;
  padding: 32px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.process:before {
  content: "";
  z-index: 1;
  background: #3c7168;
  width: 100%;
  height: 100%;
  transition: transform .3s linear;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.process__index {
  text-align: right;
  color: #696e79;
  z-index: 10;
  font-size: 48px;
  line-height: 56px;
}

.process__title {
  color: #0d1321;
  word-break: break-word;
  z-index: 10;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}

.process__list {
  color: #696e79;
  z-index: 10;
  padding: 0 0 0 16px;
  font-size: 16px;
  line-height: 24px;
}

.process_selected-from-top:before, .process_selected-from-bottom:before {
  transform: translateY(0);
}

.process_unselected-to-bottom:before {
  transform: translateY(100%);
}

.process_selected .process__index {
  color: #d3dcde;
}

.process_selected .process__title {
  color: #f9f9fa;
}

.process_selected .process__list {
  color: #d3dcde;
}

.order-section {
  flex-flow: column;
  gap: 64px 0;
  display: flex;
  overflow: hidden;
}

.order-section__background {
  filter: blur(8px);
  z-index: 1;
  background-image: url("order_background.29517885.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.order-section__background:after {
  content: "";
  z-index: 2;
  background: #0006;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.order-section__text-wrapper {
  z-index: 10;
  flex-flow: column;
  gap: 32px 0;
  display: flex;
  position: relative;
}

.order-section__title {
  color: #f9f9fa;
  font-size: 48px;
  line-height: 64px;
}

.order-section__description {
  color: #f9f9fa;
  font-size: 16px;
  line-height: 24px;
}

.order-section__contacts-wrapper {
  flex-flow: column;
  gap: 16px 0;
  display: flex;
}

.order-section__contact {
  color: #f9f9fa;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.order-section__form-wrapper {
  z-index: 10;
  position: relative;
}

.order-section__form label {
  color: #f9f9fa;
}

.footer {
  background: #0d1321;
  flex-flow: column;
  gap: 32px 0;
  padding: 32px 24px;
  display: flex;
}

.footer__content-wrapper {
  flex-flow: column;
  gap: 32px 0;
  display: flex;
}

.footer__contacts-wrapper {
  flex-flow: column;
  gap: 16px 0;
  display: flex;
}

.footer__description {
  color: #f9f9fa;
  font-size: 16px;
  line-height: 24px;
}

.footer__contacts-title {
  text-align: center;
  text-transform: uppercase;
  color: #f9f9fa;
  font-size: 24px;
  line-height: 32px;
}

.footer__contacts-list {
  flex-flow: column;
  gap: 8px 0;
  display: flex;
}

.footer__contacts-item {
  color: #f9f9fa;
  text-align: center;
}

.footer__contacts-button {
  color: #f9f9fa;
  text-transform: uppercase;
  background: #3c7168;
  border: none;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
}

.footer__laws-wrapper {
  flex-flow: column;
  gap: 8px 0;
  display: flex;
}

.footer__copy {
  color: #696e79;
  text-align: center;
  font-size: 10px;
}

.success-modal {
  z-index: 999;
  background: #eef1f0;
  flex-flow: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 64px 24px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.success-modal__message-wrapper {
  flex-flow: column;
  gap: 24px 0;
  max-width: 400px;
  display: flex;
  position: relative;
}

.success-modal__title {
  color: #0d1321;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
}

.success-modal__message {
  text-align: center;
  color: #696e79;
  font-size: 16px;
  line-height: 24px;
}

.success-modal__close-button {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  top: -40px;
  right: 0;
}

.success-modal__close-button:before, .success-modal__close-button:after {
  content: "";
  background: #0d1321;
  width: 2px;
  height: 24px;
  display: block;
  position: absolute;
}

.success-modal__close-button:before {
  transform: rotate(45deg);
}

.success-modal__close-button:after {
  transform: rotate(-45deg);
}

.order-modal {
  z-index: 999;
  backdrop-filter: blur(5px);
  background: #eef1f0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px 12px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.order-modal_visible {
  display: flex;
}

.order-modal__form-wrapper {
  position: relative;
}

.order-modal__close-button {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  top: -40px;
  right: 0;
}

.order-modal__close-button:before, .order-modal__close-button:after {
  content: "";
  background: #0d1321;
  width: 2px;
  height: 24px;
  display: block;
  position: absolute;
}

.order-modal__close-button:before {
  transform: rotate(45deg);
}

.order-modal__close-button:after {
  transform: rotate(-45deg);
}

.order-modal__form label, .order-modal__form .common-form__info-wrapper-text {
  color: #696e79;
}

.order-modal__form .common-form__contact {
  color: #696e79;
  font-weight: 500;
}

@media screen and (width >= 1025px) {
  section {
    padding: 0;
  }

  .header {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 64px;
    display: flex;
  }

  .header_mobile {
    display: none;
  }

  .header__menu-wrapper {
    align-items: center;
    gap: 0 32px;
    display: flex;
  }

  .header__logo {
    cursor: pointer;
    flex-flow: row;
    align-items: center;
    gap: 0 8px;
    display: flex;
  }

  .header__logo-name {
    flex-flow: column;
    display: flex;
  }

  .header__logo-text {
    color: #696e79;
    font-size: 12px;
  }

  .header__logo-text_big {
    font-size: 16px;
    font-weight: 600;
  }

  .header__menu-list {
    align-items: center;
    gap: 0 16px;
    list-style-type: none;
    display: flex;
  }

  .header__menu-list-item-link {
    color: #696e79;
  }

  .header__contacts-wrapper {
    flex-flow: row;
    align-items: center;
    gap: 0 16px;
    display: flex;
  }

  .header__phone-link {
    white-space: nowrap;
    font-size: 20px;
  }

  .header__call-button {
    white-space: nowrap;
    cursor: pointer;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
  }

  .first-section {
    background-image: url("first-screen_background.e847c340.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    justify-content: center;
    padding: 80px 0;
    display: flex;
  }

  .first-section__content-wrapper {
    backdrop-filter: blur(8px);
    border-radius: 32px;
    flex-flow: row;
    gap: 0 48px;
    max-width: 1000px;
    padding: 32px;
    overflow: hidden;
  }

  .first-section__info-wrapper {
    flex: 1;
  }

  .first-section__title {
    font-size: 32px;
  }

  .first-section__form-wrapper {
    flex: 1;
    grid-area: 1 / 3 / 5 / 5;
    align-self: flex-start;
    display: grid;
  }

  .first-section__feature {
    background: #eef1f0;
    border-radius: 16px;
    flex-flow: column;
    gap: 8px 0;
    padding: 24px;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .first-section__feature-title {
    font-size: 24px;
  }

  .first-section__feature-subtitle {
    color: #696e79;
  }

  .first-section__feature_first {
    grid-area: 1 / 1 / 2 / 3;
    padding: 24px 80px 24px 24px;
  }

  .first-section__feature_second {
    grid-area: 2 / 1 / 3 / 2;
    padding: 24px 24px 72px;
  }

  .first-section__feature_second .first-section__feature-title {
    font-size: 32px;
  }

  .first-section__feature_third {
    grid-area: 2 / 2 / 3 / 3;
  }

  .first-section__feature_third .first-section__feature-title {
    font-size: 32px;
  }

  .first-section__map-image {
    opacity: .6;
    width: 200px;
    position: absolute;
    top: -64px;
    right: -64px;
  }

  .first-section__mask-cars-wrapper {
    flex-flow: row;
    display: flex;
    position: relative;
  }

  .first-section__mask-cars-image {
    width: 120px;
    position: absolute;
    bottom: -80px;
    left: -40px;
  }

  .first-section__third-feature-image-wrapper {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: -20px;
    right: -20px;
    overflow: hidden;
  }

  .first-section__third-feature-image {
    width: auto;
    height: 100%;
  }

  .first-section__mask-number {
    color: #696e79;
    background: #fff9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    position: absolute;
    bottom: -80px;
    left: 64px;
  }

  .first-section__photos-wrapper {
    background: #eef1f0;
    border-radius: 24px;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 8px;
    display: grid;
  }

  .first-section__photo-wrapper {
    border-radius: 24px;
    display: flex;
    overflow: hidden;
  }

  .first-section__photo-wrapper_first {
    grid-area: 1 / 1 / 2 / 3;
  }

  .first-section__photo {
    width: 100%;
    height: auto;
    display: block;
  }

  .service-section {
    flex-flow: row;
    padding: 64px;
    display: flex;
  }

  .service-section__title-wrapper {
    gap: 16px 0;
    max-width: 600px;
    padding: 0 32px;
  }

  .service-section__title {
    font-size: 40px;
    line-height: 56px;
  }

  .service-section__subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  .service-section__calculator-wrapper {
    background: #f9f9fa;
    gap: 64px 0;
  }

  .service-section__content-wrapper {
    flex-flow: row;
    gap: 0 32px;
    display: flex;
  }

  .service-section__services-grid {
    background: #eef1f0;
    border-radius: 32px;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    width: 50%;
    padding: 32px;
  }

  .service-section__service-item {
    cursor: pointer;
    height: 80px;
    transition: background-color .125s linear, box-shadow .125s linear;
  }

  .service-section__service-item[data-size="s"] {
    font-size: 12px;
    line-height: 16px;
  }

  .service-section__service-item_selected {
    background: #3c71684d;
    border-color: #3c716899;
    font-weight: 500;
  }

  .service-section__service-item[data-index="1"] {
    grid-area: 1 / 1 / 2 / 2;
  }

  .service-section__service-item[data-index="2"] {
    grid-area: 1 / 2 / 2 / 3;
  }

  .service-section__service-item[data-index="3"] {
    grid-area: 1 / 3 / 2 / 4;
  }

  .service-section__service-item[data-index="4"] {
    grid-area: 2 / 1 / 3 / 2;
  }

  .service-section__service-item[data-index="5"] {
    grid-area: 2 / 2 / 3 / 3;
  }

  .service-section__service-item[data-index="6"] {
    grid-area: 2 / 3 / 3 / 4;
  }

  .service-section__service-item[data-index="7"] {
    grid-area: 3 / 1 / 4 / 2;
  }

  .service-section__service-item[data-index="8"] {
    grid-area: 3 / 2 / 4 / 3;
  }

  .service-section__service-item[data-index="9"] {
    grid-area: 3 / 3 / 4 / 4;
  }

  .service-section__service-item[data-index="10"] {
    grid-area: 4 / 1 / 5 / 4;
  }

  .service-section__selected-service-description {
    box-shadow: none;
    background: #eef1f0;
    border-radius: 32px;
    gap: 24px 0;
    min-width: 400px;
    max-width: 400px;
    padding: 24px;
    display: flex;
    position: static;
  }

  .service-section__selected-service-value {
    display: none;
  }

  .service-section__perks-list-wrapper {
    flex-flow: column;
    gap: 16px 0;
    display: flex;
  }

  .service-section__perks-list-image-wrapper {
    min-height: 250px;
    max-height: 250px;
  }

  .service-section__selected-service-price {
    font-size: 32px;
    line-height: 40px;
  }

  .service-section__perks-list {
    width: 100%;
    padding: 0 0 0 24px;
  }

  .service-section__perks-list-item {
    color: #696e79;
    font-size: 16px;
    line-height: 24px;
  }

  .reviews-section {
    justify-content: center;
    align-items: center;
    padding: 64px;
    display: flex;
  }

  .reviews-section__content-wrapper {
    background: #eef1f0;
    border-radius: 32px;
    flex-flow: column;
    gap: 32px 0;
    max-width: 1000px;
    padding: 64px;
    display: flex;
  }

  .reviews-section__title {
    width: 70%;
    font-size: 48px;
    line-height: 56px;
  }

  .reviews-section__reviews-list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row;
    gap: 32px;
    display: grid;
  }

  .reviews-section .review__text {
    -webkit-line-clamp: 8;
    line-clamp: 8;
    -webkit-box-orient: vertical;
    flex: 1;
    display: -webkit-box;
    overflow: hidden;
  }

  .reviews-section__load-more-button {
    color: #696e79;
    background: #ece9e9;
    border: none;
    border-radius: 8px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
  }

  .features-section {
    padding: 80px 0;
  }

  .features-section__subtitle {
    max-width: 800px;
  }

  .features-section__features-list {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    align-self: center;
    max-width: 1000px;
    display: grid;
  }

  .features-section .feature_first {
    grid-area: 1 / 1 / 2 / 2;
  }

  .features-section .feature_third {
    grid-area: 2 / 1 / 3 / 2;
  }

  .features-section .feature_second {
    flex-flow: column;
    grid-area: 1 / 2 / 3 / 3;
  }

  .feature {
    flex-flow: row;
  }

  .feature__text-wrapper {
    flex-flow: column;
    flex: 1;
    gap: 12px 0;
    padding: 24px;
    display: flex;
  }

  .feature__image-wrapper {
    justify-content: flex-end;
    align-items: center;
    min-width: 150px;
    display: flex;
  }

  .feature__image-wrapper_padding {
    padding: 16px;
  }

  .feature__image {
    width: 200px;
    height: auto;
  }

  .feature__image_third {
    border-radius: 24px;
    flex-flow: column;
    width: 100%;
    min-height: 300px;
  }

  .process-section {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .process-section__content-wrapper {
    max-width: 1000px;
  }

  .process-section .processes__wrapper {
    flex-flow: row;
    gap: 0 24px;
    display: flex;
  }

  .process {
    transition: background-color .3s linear;
  }

  .process:before {
    display: none;
  }

  .process_selected {
    background: #3c7168;
  }

  .order-section {
    flex-flow: row;
    gap: 0 64px;
    min-height: auto;
    padding: 80px 64px;
    display: flex;
  }

  .order-section__background {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
  }

  .order-section__text-wrapper {
    z-index: 10;
    flex-flow: column;
    gap: 32px 0;
    width: 50%;
    display: flex;
    position: relative;
  }

  .order-section__title {
    color: #f9f9fa;
    font-size: 48px;
    line-height: 64px;
  }

  .order-section__description {
    color: #f9f9fa;
    font-size: 20px;
    line-height: 28px;
  }

  .order-section__contacts-wrapper {
    flex-flow: column;
    gap: 16px 0;
    display: flex;
  }

  .order-section__contact {
    color: #f9f9fa;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
  }

  .order-section__form-wrapper {
    z-index: 10;
    justify-content: center;
    width: 50%;
    display: flex;
    position: relative;
  }

  .order-section__form {
    width: 400px;
  }

  .order-section__form label {
    color: #f9f9fa;
  }

  .footer {
    padding: 64px 64px 32px;
  }

  .footer__content-wrapper {
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    gap: 0 64px;
    display: flex;
  }

  .footer__contacts-wrapper, .footer__description {
    flex: 1;
    width: 30%;
  }

  .footer__map {
    flex: 1;
    align-items: flex-start;
    width: 30%;
    max-height: 200px;
    display: flex;
    overflow: hidden;
  }

  .footer__contacts-item {
    color: #f9f9fa;
    text-align: center;
  }

  .footer__contacts-button {
    cursor: pointer;
  }

  .footer__laws-wrapper {
    flex-flow: row;
    gap: 0 32px;
  }

  .regions-modal {
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .regions-modal__list-wrapper {
    border-radius: 32px;
    min-width: 460px;
    max-height: 60%;
    padding: 32px 32px 0;
  }

  .regions-modal__select-button, .regions-modal .regions-dropdown__region-list-item {
    cursor: pointer;
  }

  .order-modal {
    flex-flow: column;
    gap: 32px 0;
  }

  .order-modal_visible {
    display: flex;
  }

  .order-modal__close-button {
    top: -32px;
    right: -32px;
  }

  .order-modal__form {
    width: 460px;
  }

  .order-modal__form-description {
    color: #696e79;
    text-align: center;
    max-width: 460px;
    font-size: 16px;
    line-height: 24px;
  }

  .order-modal .common-form label, .order-modal .common-form__contact, .order-modal .common-form__info-wrapper-text {
    color: #696e79;
  }

  .common-form label, .common-form__contact, .common-form__info-wrapper-text {
    color: #f9f9fa;
  }
}
/*# sourceMappingURL=index.67d081b3.css.map */
