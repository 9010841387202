$backgroundPrimaryColor: #F9F9FA;
$backgroundSecondaryColor: #EEF1F0;
$backgroundAlternativeColor: #ECE9E9;
$backgroundFooterColor: #0D1321;
$textPrimaryColor: #0D1321;
$textSecondaryColor: #696E79;
$textAlternativeColor: #D3DCDE;
$textPrimaryInverseColor: #F9F9FA;
$actionPrimaryColor: #3C7168;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

div, ul, li, span, h1, h2, h3, h4, h5, h6, p, a, label, input {
  font-family: "IBM Plex Sans", sans-serif;
  font-style: normal;
}

a {
  text-decoration: none;
}

.modal-caller {
  cursor: pointer;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  gap: 0 24px;
  padding: 16px 16px;
  z-index: 999;
  position: sticky;
  top: 0;
  background: $backgroundSecondaryColor;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(105, 110, 121, .1);
  transform: translateY(-100%);
  transition: transform .125s linear;

  &_hidden {
    display: none;
    pointer-events: none;
    z-index: -1;
  }

  &_placed {
    transform: translateY(0);
  }

  &__phone-link {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    color: $textPrimaryColor;
  }

  &__call-button {
    flex: 1;
    border: none;
    background: $actionPrimaryColor;
    color: $textPrimaryInverseColor;
    border-radius: 8px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px;
  }
}

.regions-modal {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  z-index: 9999;

  &__list-wrapper {
    background: $backgroundSecondaryColor;
    padding: 32px 0 0 0;
    height: calc(100% - 64px);
    overflow: auto;
    border-radius: 32px 32px 0 0;
  }

  &__select-button {
    height: 48px;
    border: none;
    background: $actionPrimaryColor;
    color: $textPrimaryInverseColor;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__selected-value {
    color: $textSecondaryColor;
    font-weight: 500;
  }

  &__regions-list {
    display: flex;
    flex-flow: column nowrap;
    list-style-type: none;
    gap: 16px 0;
    padding: 0 16px 16px 16px;
  }

  &__selected-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px 0;
    position: sticky;
    bottom: 16px;
    background: $backgroundPrimaryColor;
    padding: 24px 16px;
    border-radius: 24px;
    box-shadow: 0 2px 8px 0 rgba(105, 110, 121, .1);
  }

  .regions-dropdown__region-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 64px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(105, 110, 121, .1);
    font-size: 16px;
    line-height: 24px;
    color: $textSecondaryColor;
    font-weight: 400;
    border: 1px solid transparent;
    background: $backgroundPrimaryColor;
    text-align: center;
    transition: all .125s linear;

    &_selected {
      border-color: rgba(60, 113, 104, .6);
      background: rgba(60, 113, 104, .3);
      font-weight: 500;
    }
  }
}

section {
  min-height: 100vh;
  height: 100%;
  position: relative;
  background: $backgroundPrimaryColor;
  padding: 64px 32px;
}

.section-title {
  color: $textPrimaryColor;
}

.section-subtitle {
  color: $textSecondaryColor;
}

.common-form {
  display: flex;
  flex-flow: column nowrap;
  background: rgba(60, 113, 104, .3);
  padding: 24px;
  border-radius: 32px;
  gap: 32px 0;

  &__fields-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px 0;
  }

  label {
    display: flex;
    flex-flow: column nowrap;
    gap: 4px 0;
    font-size: 12px;
    line-height: 20px;
    color: $textSecondaryColor;
  }

  &__input {
    height: 64px;
    border: none;
    border-radius: 8px;
    text-align: center;
    font-size: 24px;
    color: $textSecondaryColor;
    font-weight: 500;
    background: $backgroundSecondaryColor;
    display: flex;
    align-items: center;
    justify-content: center;

    &::placeholder {
      color: $textAlternativeColor;
    }
    &_location {
      cursor: pointer;
    }
  }

  &__submit {
    height: 64px;
    border: none;
    background: $actionPrimaryColor;
    color: $textPrimaryInverseColor;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color .125s linear;

    &[disabled] {
      background: $textSecondaryColor;
      cursor: not-allowed;
    }
  }

  &__attention-text {
    text-align: center;
    color: $textPrimaryInverseColor;
    opacity: .6;
  }

  &__info-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;

    &-text {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      color: $textSecondaryColor;
    }
  }

  &__contact {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: $textSecondaryColor;
  }
}

.first-section {
  &__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 32px 0;
  }
  &__info-wrapper {
    display: flex;
    gap: 32px 0;
    flex-flow: column nowrap;
  }
  &__title-wrapper {
    background: $backgroundSecondaryColor;
    padding: 32px;
    border-radius: 32px;
  }
  &__features-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 24px;
  }

  &__feature {
    background: $backgroundSecondaryColor;
    padding: 24px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;

    &-title {
      font-size: 20px;
    }
    &_first  {
      grid-area: 1 / 1 / 2 / 3;
      padding: 24px 80px 24px 24px;
    }
    &_second {
      grid-area: 2 / 1 / 3 / 2;
      padding: 24px 24px 72px 24px;
      .first-section__feature-title {
        font-size: 32px;
      }
    }
    &_third {
      grid-area: 2 / 2 / 3 / 3;
      .first-section__feature-title {
        font-size: 32px;
      }
    }
  }

  &__map-image {
    position: absolute;
    width: 200px;
    top: -64px;
    right: -64px;
    opacity: .6;
  }

  &__mask-cars-wrapper {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
  }

  &__mask-cars-image {
    width: 120px;
    position: absolute;
    left: -40px;
    bottom: -80px;
  }

  &__third-feature-image-wrapper {
    height: 90px;
    width: 90px;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    bottom: -20px;
    right: -20px;
  }

  &__third-feature-image {
    width: auto;
    height: 100%;
  }

  &__mask-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .6);
    font-size: 16px;
    color: $textSecondaryColor;
    font-weight: 600;
    position: absolute;
    left: 64px;
    bottom: -80px;
  }

  &__photos-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 24px;
    background: $backgroundSecondaryColor;
    padding: 8px;
    border-radius: 24px;
  }

  &__photo-wrapper {
    display: flex;
    overflow: hidden;
    border-radius: 24px;

    &_first {
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  &__photo {
    display: block;
    width: 100%;
    height: auto;
  }

}

.service-section {
  padding: 0;

  &__form-wrapper {
    display: none;
  }

  &__calculator-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 48px 0;
    background: $backgroundSecondaryColor;
    padding: 64px 0;
  }

  &__title-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;
    padding: 0 32px;
  }

  &__title {
    font-size: 36px;
    line-height: 42px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  &__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 48px 0;
  }

  &__services-grid {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 24px 0;
    padding: 0 32px;
  }

  &__service-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 64px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(105,110,121,.1);
    font-size: 16px;
    line-height: 24px;
    color: $textSecondaryColor;
    font-weight: 400;
    border: 1px solid transparent;
    background: $backgroundPrimaryColor;
    text-align: center;
    transition: all .125s linear;

    &_selected {
      border-color: rgba(60, 113, 104, .6);
      background: rgba(60, 113, 104, .3);
      font-weight: 500;
    }
  }

  &__selected-service-content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px 0;
    order: 1;
  }

  &__toggle-mobile-service-button {
    border: none;
    font-size: 16px;
    height: 32px;
    border-radius: 8px;
    background: $backgroundAlternativeColor;
    color: $textSecondaryColor;
    font-weight: 600;
    position: relative;
  }

  &__selected-service-description {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;
    position: sticky;
    bottom: 0;
    background: $backgroundPrimaryColor;
    padding: 32px 32px 16px 32px;
    border-radius: 24px 24px 0 0;
    box-shadow: 0 -4px 8px 0 rgba(105,110,121,.1);
  }

  &__perks-list-wrapper {
    flex-flow: row nowrap;
    gap: 0 16px;
    align-items: center;
    display: none;

    &_expanded {
      display: flex;
      max-height: 100%;
    }
  }

  &__perks-list-image-wrapper {
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__perks-list-image {
    width: 100%;
    height: auto;
    display: block;
  }

  &__selected-service-price {
    font-size: 24px;
    line-height : 32px;
    font-weight: 600;
    color: $textPrimaryColor;
  }

  &__perks-list {
    padding: 0 0 0 24px;

    &-item {
      font-size: 16px;
      line-height: 24px;
      color: $textSecondaryColor;
    }
  }

  &__selected-service-value {
    color: $textSecondaryColor;
    font-weight: 500;
    height: 32px;
  }

  &__call-form-button {
    height: 48px;
    border: none;
    background: $actionPrimaryColor;
    color: $textPrimaryInverseColor;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
    order: 2;
  }
}

.reviews-section {
  &__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 32px 0;
    background: $backgroundSecondaryColor;
    padding: 24px;
    border-radius: 32px;
  }
  &__title {
    font-size: 32px;
    line-height: 40px;
  }

  &__reviews-list {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;
  }

  &__load-more-button {
    border: none;
    font-size: 16px;
    height: 48px;
    border-radius: 8px;
    background: $backgroundAlternativeColor;
    color: $textSecondaryColor;
    font-weight: 600;
  }
}

.review {
  display: flex;
  flex-flow: column nowrap;
  background: $textPrimaryInverseColor;
  border-radius: 32px;
  gap: 12px 0;

  &__image-wrapper {
    width: calc(100% - 48px);
    transform: translateX(24px) translateY(24px);
    max-height: 240px;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    //padding: 24px;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__text {
    color: $textSecondaryColor;
    font-size: 16px;
    line-height: 24px;
    padding: 24px 24px 0 24px;
  }

  &__author-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    padding: 0 16px 16px 16px;
  }

  &__author {
    font-size: 16px;
    line-height: 24px;
    color: $textPrimaryColor;
    font-weight: 600;
  }
}

.title-badge {
  padding: 12px 16px;
  color: $textSecondaryColor;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid $textAlternativeColor;
  border-radius: 32px;
  background: $backgroundAlternativeColor;
  text-align: center;
  justify-self: center;
  width: min-content;
  white-space: nowrap;
}

.features-section {
  display: flex;
  flex-flow: column nowrap;
  gap: 80px 0;

  &__title-wrapper {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    gap: 24px 0;
  }

  &__title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }

  &__features-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 32px 0;
  }
}

.feature {
  display: flex;
  flex-flow: column nowrap;
  background: $backgroundSecondaryColor;
  border-radius: 24px;
  overflow: hidden;

  &__text-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 12px 0;
    padding: 24px 24px 0 24px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: $textSecondaryColor;
  }

  &__image-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 16px 0;

    &_padding {
      padding: 16px;
    }
  }

  &__image {
    width: 50%;
    height: auto;

    &_third {
      width: 100%;
      border-radius: 24px;
    }
  }
}

.process-section {
  &__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    background: $backgroundSecondaryColor;
    border-radius: 32px;
    padding: 32px 24px;
    gap: 64px 0;
  }
  &__title {
    font-size: 48px;
    line-height: 56px;
  }
  .processes__wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px 0;
  }
}

.process {
  display: flex;
  flex-flow: column nowrap;
  gap: 32px 0;
  border-radius: 24px;
  background: $backgroundPrimaryColor;
  padding: 32px 24px;
  position: relative;
  overflow: hidden;
  flex: 1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $actionPrimaryColor;
    transform: translateY(-100%);
    transition: transform .3s linear;
    z-index: 1;
  }

  &__index {
    text-align: right;
    font-size: 48px;
    line-height: 56px;
    color: $textSecondaryColor;
    z-index: 10;
  }

  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    color: $textPrimaryColor;
    word-break: break-word;
    z-index: 10;
  }

  &__list {
    font-size: 16px;
    line-height: 24px;
    color: $textSecondaryColor;
    z-index: 10;
    padding: 0 0 0 16px;
  }

  &_selected-from-top {
    &::before {
      transform: translateY(0);
    }
  }

  &_selected-from-bottom {
    &::before {
      transform: translateY(0);
    }
  }

  &_unselected-to-bottom {
    &::before {
      transform: translateY(100%);
    }
  }

  &_selected {
    .process__index {
      color: $textAlternativeColor;
    }

    .process__title {
      color: $textPrimaryInverseColor;
    }

    .process__list {
      color: $textAlternativeColor;
    }
  }
}

.order-section {
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  gap: 64px 0;
  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/order_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    z-index: 1;

    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.4);
      z-index: 2;
    }
  }

  &__text-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-flow: column nowrap;
    gap: 32px 0;
  }

  &__title {
    color: $textPrimaryInverseColor;
    font-size: 48px;
    line-height: 64px;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: $textPrimaryInverseColor;
  }

  &__contacts-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px 0;
  }

  &__contact {
    color: $textPrimaryInverseColor;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
  }

  &__form-wrapper {
    position: relative;
    z-index: 10;
  }

  &__form {
    label {
      color: $textPrimaryInverseColor;
    }
  }

}

.footer {
  display: flex;
  flex-flow: column nowrap;
  padding: 32px 24px;
  gap: 32px 0;
  background: $backgroundFooterColor;

  &__content-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 32px 0;
  }

  &__contacts-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px 0;
  }

  &__description {
    color: $textPrimaryInverseColor;
    font-size: 16px;
    line-height: 24px;
  }

  &__contacts-title {
    text-align: center;
    text-transform: uppercase;
    color: $textPrimaryInverseColor;
    font-size: 24px;
    line-height: 32px;
  }

  &__contacts-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;
  }

  &__contacts-item {
    color: $textPrimaryInverseColor;
    text-align: center;
  }

  &__contacts-button {
    height: 48px;
    border: none;
    background: $actionPrimaryColor;
    color: $textPrimaryInverseColor;
    border-radius: 8px;
    font-size: 16px;
    text-transform: uppercase;
  }

  &__laws-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px 0;
  }

  &__copy {
    color: $textSecondaryColor;
    text-align: center;
    font-size: 10px;
  }
}

//Success message
.success-modal {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  padding: 64px 24px;
  background: $backgroundSecondaryColor;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;

  &__message-wrapper {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    gap: 24px 0;
    max-width: 400px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    color: $textPrimaryColor;
    text-align: center;
  }

  &__message {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $textSecondaryColor;
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    background: transparent;
    right: 0;
    top: -40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before, &::after {
      content: '';
      display: block;
      width: 2px;
      height: 24px;
      position: absolute;
      background: $textPrimaryColor;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}






// Modal
.order-modal {
  display: none;
  flex-flow: column nowrap;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: $backgroundSecondaryColor;
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
  padding: 24px 12px;
  &_visible {
    display: flex;
  }

  &__form-wrapper {
    position: relative;
  }

  &__close-button {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    background: transparent;
    right: 0;
    top: -40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before, &::after {
      content: '';
      display: block;
      width: 2px;
      height: 24px;
      position: absolute;
      background: $textPrimaryColor;
    }
    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__form {
    label {
      color: $textSecondaryColor;
    }
    .common-form__info-wrapper-text {
      color: $textSecondaryColor;
    }
    .common-form__contact {
      color: $textSecondaryColor;
      font-weight: 500;
    }
  }
}

@media screen and (min-width: 1025px) {
  section {
    padding: 0;
  }

  .header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 64px;

    &_mobile {
      display: none;
    }

    &__menu-wrapper {
      display: flex;
      align-items: center;
      gap: 0 32px;
    }

    &__logo {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;
      gap: 0 8px;

      &-name {
        display: flex;
        flex-flow: column nowrap;
      }
    }

    &__logo-text {
      color: $textSecondaryColor;
      font-size: 12px;

      &_big {
        font-weight: 600;
        font-size: 16px;
      }
    }

    &__menu-list {
      display: flex;
      align-items: center;
      list-style-type: none;
      gap: 0 16px;
    }

    &__menu-list-item-link {
      color: $textSecondaryColor;
    }

    &__contacts-wrapper {
      gap: 0 16px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    &__phone-link {
      white-space: nowrap;
      font-size: 20px;
    }

    &__call-button {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      padding: 12px 16px;
      cursor: pointer;
    }
  }

  .first-section {
    justify-content: center;
    display: flex;
    padding: 80px 0;
    background-image: url('../assets/img/first-screen_background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    &__content-wrapper {
      max-width: 1000px;
      flex-flow: row nowrap;
      gap: 0 48px;
      backdrop-filter: blur(8px);
      padding: 32px;
      border-radius: 32px;
      overflow: hidden;
    }
    &__info-wrapper {
      flex: 1;
    }
    &__title {
      font-size: 32px;
    }
    &__form-wrapper {
      flex: 1;
      display: grid;
      grid-area: 1 / 3 / 5 / 5;
      align-self: flex-start;
    }

    &__feature {
      background: $backgroundSecondaryColor;
      padding: 24px;
      border-radius: 16px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-flow: column nowrap;
      gap: 8px 0;

      &-title {
        font-size: 24px;
      }
      &-subtitle {
        color: $textSecondaryColor;
      }
      &_first  {
        grid-area: 1 / 1 / 2 / 3;
        padding: 24px 80px 24px 24px;
      }
      &_second {
        grid-area: 2 / 1 / 3 / 2;
        padding: 24px 24px 72px 24px;
        .first-section__feature-title {
          font-size: 32px;
        }
      }
      &_third {
        grid-area: 2 / 2 / 3 / 3;
        .first-section__feature-title {
          font-size: 32px;
        }
      }
    }

    &__map-image {
      position: absolute;
      width: 200px;
      top: -64px;
      right: -64px;
      opacity: .6;
    }

    &__mask-cars-wrapper {
      display: flex;
      flex-flow: row nowrap;
      position: relative;
    }

    &__mask-cars-image {
      width: 120px;
      position: absolute;
      left: -40px;
      bottom: -80px;
    }

    &__third-feature-image-wrapper {
      height: 90px;
      width: 90px;
      overflow: hidden;
      position: absolute;
      border-radius: 50%;
      bottom: -20px;
      right: -20px;
    }

    &__third-feature-image {
      width: auto;
      height: 100%;
    }

    &__mask-number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: rgba(255, 255, 255, .6);
      font-size: 16px;
      color: $textSecondaryColor;
      font-weight: 600;
      position: absolute;
      left: 64px;
      bottom: -80px;
    }

    &__photos-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 24px;
      background: $backgroundSecondaryColor;
      padding: 8px;
      border-radius: 24px;
    }

    &__photo-wrapper {
      display: flex;
      overflow: hidden;
      border-radius: 24px;

      &_first {
        grid-area: 1 / 1 / 2 / 3;
      }
    }

    &__photo {
      display: block;
      width: 100%;
      height: auto;
    }

  }

  .service-section {
    display: flex;
    flex-flow: row nowrap;
    padding: 64px;

    &__title-wrapper {
      max-width: 600px;
      gap: 16px 0;
      padding: 0 32px;
    }

    &__title {
      font-size: 40px;
      line-height: 56px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 24px;
    }

    &__calculator-wrapper {
      gap: 64px 0;
      background: $backgroundPrimaryColor;
    }

    &__content-wrapper {
      display: flex;
      flex-flow: row nowrap;
      gap: 0 32px;
    }

    &__services-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      gap: 24px;
      padding: 32px;
      border-radius: 32px;
      background: $backgroundSecondaryColor;
      width: 50%;
    }

    &__service-item {
      transition: background-color .125s linear, box-shadow .125s linear;
      cursor: pointer;
      height: 80px;

      &[data-size="s"] {
        font-size: 12px;
        line-height: 16px;
      }

      &_selected {
        border-color: rgba(60, 113, 104, .6);
        background: rgba(60, 113, 104, .3);
        font-weight: 500;
      }

      &[data-index="1"] { grid-area: 1 / 1 / 2 / 2; }
      &[data-index="2"] { grid-area: 1 / 2 / 2 / 3; }
      &[data-index="3"] { grid-area: 1 / 3 / 2 / 4; }
      &[data-index="4"] { grid-area: 2 / 1 / 3 / 2; }
      &[data-index="5"] { grid-area: 2 / 2 / 3 / 3; }
      &[data-index="6"] { grid-area: 2 / 3 / 3 / 4; }
      &[data-index="7"] { grid-area: 3 / 1 / 4 / 2; }
      &[data-index="8"] { grid-area: 3 / 2 / 4 / 3; }
      &[data-index="9"] { grid-area: 3 / 3 / 4 / 4; }
      &[data-index="10"] { grid-area: 4 / 1 / 5 / 4; }
    }

    &__selected-service-description {
      display: flex;
      position: static;
      box-shadow: none;
      padding: 24px;
      border-radius: 32px;
      background: $backgroundSecondaryColor;
      min-width: 400px;
      max-width: 400px;
      gap: 24px 0;
    }

    &__selected-service-value {
      display: none;
    }

    &__perks-list-wrapper {
      display: flex;
      flex-flow: column nowrap;
      gap: 16px 0;
    }

    &__perks-list-image-wrapper {
      min-height: 250px;
      max-height: 250px;
    }

    &__selected-service-price {
      font-size: 32px;
      line-height : 40px;
    }

    &__perks-list {
      width: 100%;
      padding: 0 0 0 24px;

      &-item {
        font-size: 16px;
        line-height: 24px;
        color: $textSecondaryColor;
      }
    }

  }

  .reviews-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px;
    &__content-wrapper {
      max-width: 1000px;
      display: flex;
      flex-flow: column nowrap;
      gap: 32px 0;
      background: $backgroundSecondaryColor;
      padding: 64px;
      border-radius: 32px;
    }

    &__title {
      width: 70%;
      font-size: 48px;
      line-height: 56px;
    }

    &__reviews-list {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 32px;
    }

    .review__text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      line-clamp: 8;
      -webkit-box-orient: vertical;
      flex: 1;
    }

    &__load-more-button {
      border: none;
      font-size: 16px;
      height: 48px;
      border-radius: 8px;
      background: $backgroundAlternativeColor;
      color: $textSecondaryColor;
      font-weight: 600;
    }
  }

  .features-section {
    padding: 80px 0;
    &__subtitle {
      max-width: 800px;
    }

    &__features-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      max-width: 1000px;
      align-self: center;
    }

    .feature_first { grid-area: 1 / 1 / 2 / 2; }
    .feature_third { grid-area: 2 / 1 / 3 / 2; }
    .feature_second {
      grid-area: 1 / 2 / 3 / 3;
      flex-flow: column nowrap;
    }
  }

  .feature {
    flex-flow: row nowrap;

    &__text-wrapper {
      display: flex;
      flex-flow: column nowrap;
      gap: 12px 0;
      padding: 24px;
      flex: 1;
    }

    &__image-wrapper {
      display: flex;
      justify-content: flex-end;
      min-width: 150px;
      align-items: center;


      &_padding {
        padding: 16px;
      }
    }

    &__image {
      width: 200px;
      height: auto;

      &_third {
        flex-flow: column nowrap;
        width: 100%;
        min-height: 300px;
        border-radius: 24px;
      }
    }
  }

  .process-section {
    display: flex;
    justify-content: center;
    align-items: center;
    &__content-wrapper {
      max-width: 1000px;
    }

    .processes__wrapper {
      display: flex;
      flex-flow: row nowrap;
      gap: 0 24px;
    }
  }

  .process {
    transition: background-color .3s linear;
    &::before {
      display: none;
    }

    &_selected {
      background: $actionPrimaryColor;
    }
  }

  .order-section {
    display: flex;
    flex-flow: row nowrap;
    gap: 0 64px;
    min-height: auto;
    padding: 80px 64px;
    &__background {
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
    }

    &__text-wrapper {
      position: relative;
      z-index: 10;
      display: flex;
      flex-flow: column nowrap;
      gap: 32px 0;
      width: 50%;

    }

    &__title {
      color: $textPrimaryInverseColor;
      font-size: 48px;
      line-height: 64px;
    }

    &__description {
      font-size: 20px;
      line-height: 28px;
      color: $textPrimaryInverseColor;
    }

    &__contacts-wrapper {
      display: flex;
      flex-flow: column nowrap;
      gap: 16px 0;
    }

    &__contact {
      color: $textPrimaryInverseColor;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
    }

    &__form-wrapper {
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: center;
      width: 50%;
    }

    &__form {
      width: 400px;
      label {
        color: $textPrimaryInverseColor;
      }
    }

  }

  .footer {
    padding: 64px 64px 32px 64px;

    &__content-wrapper {
      display: flex;
      flex-flow: row nowrap;
      gap: 0 64px;
      justify-content: space-between;
      align-items: center;
    }

    &__contacts-wrapper {
      width: 30%;
      flex: 1;
    }

    &__description {
      width: 30%;
      flex: 1;
    }

    &__map {
      width: 30%;
      flex: 1;
      max-height: 200px;
      overflow: hidden;
      display: flex;
      align-items: flex-start;
    }

    &__contacts-item {
      color: $textPrimaryInverseColor;
      text-align: center;
    }

    &__contacts-button {
      cursor: pointer;
    }

    &__laws-wrapper {
      flex-flow: row nowrap;
      gap: 0 32px;
    }
  }

  .regions-modal {
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    &__list-wrapper {
      min-width: 460px;
      max-height: 60%;
      padding: 32px 32px 0 32px;
      border-radius: 32px;
    }

    &__select-button {
      cursor: pointer;
    }

    .regions-dropdown__region-list-item {
      cursor: pointer;
    }
  }

  .order-modal {
    flex-flow: column nowrap;
    gap: 32px 0;
    &_visible {
      display: flex;
    }

    &__close-button {
      right: -32px;
      top: -32px;
    }

    &__form {
      width: 460px;
    }

    &__form-description {
      max-width: 460px;
      font-size: 16px;
      line-height: 24px;
      color: $textSecondaryColor;
      text-align: center;
    }

    .common-form {
      label, &__contact, &__info-wrapper-text {
        color: $textSecondaryColor;
      }
    }
  }

  .common-form {
    label, &__contact, &__info-wrapper-text {
      color: $textPrimaryInverseColor;
    }
  }
}